import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgCaseStudy from "../images/flagfinders-case-study-team-wide.jpg"
import imgClare from "../images/bullocks-case-study-clare.jpg"
import imgVehicles from "../images/bullocks-case-study.jpg"
import imgStudentQRCode from "../images/student-coach-qr-code.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Bullocks accomplished months of work in minutes with ShuttleID" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">Case study: Months of work completed in minutes and hundreds of happy parents. They’re the Bullocks!</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">Case study: Months of work completed in minutes and hundreds of happy parents. They’re the Bullocks!</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>The 2020 Covid pandemic meant a reduction in staff during one of the busiest times in Bullocks Coaches’ admin calendar.</p>
                    <p>As parents began booking seats on the school coaches for the September term, directors Alan and daughter Clare stepped up to process the travel passes. Hours in, Alan and Clare realised just how labour-intensive and flawed running a manual application, ticket and payment system really was.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>250+</span>
                        annual admin hours saved
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>285,000</span>
                        journeys protected annually
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>What a lot of Bullocks</h3>
                      <p style={{ marginBottom: '1em' }}>In 1928, grandad Ralph Bullock set out to deliver milk from farms to dairies across the North West. He perhaps didn’t envisage how successful his enterprise would become. Almost 100 years later, the family firm is still taking huge pride in delivering ‘joy in every journey’, albeit with a very different precious cargo on board.</p>
                      <p>Four generations of Bullocks have transformed the original haulage company into a thriving coach business. Today, they offer school services, corporate transport, leisure trips and private hire from their base in Cheadle, near Manchester. And with 750 independent school travellers, the family says it best themselves – “We’re not like other coach companies. We’re the Bullocks!”</p>
                    </div>

                    <h2>Manual checks across multiple records: “The middle of July to the middle of September was full on.  We were double-checking everything.”</h2>
                    <p>Stepping into the breach, Alan Bullock saw first-hand the chaos surrounding the creation of passes for children wanting to travel on the company’s coaches in the coming school year. </p>
                    <p>The manual process was clunky and open to human error. For the whole of August, Alan and Clare spent seven days a week matching application forms to student photographs, taking phone payments, chasing non-payments or banking cheques. There were around 750 applications to process and invariably many had information missing. The pair resorted to spreadsheets to cross-check each stage of every application.</p>
                    <p>“I bet it was pushing 300 hours between the two of us just doing passes. I thought we did quite a reasonable job, but I was already thinking there must be a better way than this,” said Alan.</p>
                    <p>As the new school term approached, there was the usual last-minute dash for tickets.  Clare said, “A new pass could take anywhere from one week to a month and some students received theirs after the start of new term”. </p>
                    <p><img src={imgVehicles} alt="Bullocks Coaches" /></p>

                    {/* <p>
                      <img style={{ marginBottom: 0 }} src={imgCaseStudy} alt="Flagfinders team" />
                      <span><em>The Flagfinders team: Wendy, Lesley and Trish</em></span>
                    </p> */}

                    <h2>Immediate results with ShuttleID</h2>
                    <p>A quick demonstration of ShuttleID’s purposely designed school service system offered hope.</p>
                    <p>With everything in one complete platform, a new bus pass application suddenly took parents three minutes to complete, with passes issued instantly.  “That’s because the system collects all the right information, with photos in the correct format, and payment is digitised via GoCardless,” said Emma from ShuttleID. For Bullocks, the paper trail disappeared overnight, and the weary data cross-checking became a thing of the past. “All that was just so time-consuming,” said Alan.</p>
                    <p>As well as digitising every step from application to renewal, ShuttleID presented Bullocks with a host of features and reports they have since found invaluable.</p>

                    <h2>Precision and control</h2>
                    <p>The system’s inbuilt email verification proved a major forward step. Alan said, “Very often, parents with a Gmail or Hotmail account found our emails in their junk folder. Having parents write their emails down led to mistakes too. That problem has all gone”</p>
                    <p>The advantage of digital timestamps now means Alan and Clare know exactly when a parent joins a waitlist or when payments are made/declined. Crucially, pass renewals are handled automatically. “Before, all the new and existing application forms were mixed up in a pile. Those who left it until September to renew didn’t get a place on the bus and they kicked up a stink,” said Alan</p>
                    <p>“The renewal button saves us so much time – I hadn’t fully appreciated that. Combined with the waitlist, we can really control the new intake. I just download the list and split it by service. It’s a powerful, time-saving tool.”</p>
                    <p>In fact, in the first year, over half of parents renewed within a week of the notification email being sent out. Even for those late to the party, the system coped with last-minute demand freeing Clare and Alan to focus on their main roles.</p>
                    <p>
                      <img style={{ marginBottom: 0 }} src={imgClare} alt="Clare Bullock" />
                      <span><em>Clare Bullock, Operations Manager</em></span>
                    </p>

                    <h2>Hundreds of happy parents</h2>
                    <p>Parents are happy because they don’t have to wait for passes and they relax once they know their child has a place on the coach. Plus, they’re only paying for services they use.</p>
                    <p>“The custom ticket option is so much easier,” said Alan. “I set up all our routes in ShuttleID in several hours, and I’m no techie! Parents can buy single passes, or I’ve got quite a few on custom options that suit them better, for example morning and afternoon services but only Monday to Thursday. Before, my manual system wouldn’t have coped with that.”</p>
                    <p><img src={imgStudentQRCode} alt="Student using QR code" /></p>
                    <p>Bullocks’ former colour-coded system relied on drivers checking ticket validity. Physical tickets also had the potential for fraud with their handwritten ‘valid until’ date. Alan said, "The drivers didn't take to the other systems we tried, but they have taken to this. ShuttleID tickets are barcoded and scanned automatically for authenticity. It means drivers have less to worry about.”</p>
                    <p>Parents love the service alerts, which preempt queries about delayed coaches due to traffic or weather events. Calls to Bullocks’ office have reduced as parents catch on quickly to the ShuttleID system. Clare said, “One parent told us she booked both children, at two different schools, onto our coaches – all while on a 10-minute taxi journey to the station.”</p>

                    <h2>More power, less effort</h2>
                    <p>Reducing the manpower time has been the major advantage to Bullocks, which manages passes on an annual basis. Alan says, “Imagine if you were doing passes termly – there’s potentially an even bigger time saving to be made.”</p>
                    <p>With an accurate picture of who is travelling where and when, Bullocks has been able to maximise capacity on every route and even waxes lyrical of the benefits to others. He said, “We’ve been demonstrating it to new schools off our own back because it’s a win-win for schools and parents.</p>

                    {/* <div className='case-study__quote'>
                      <p>"Before, students were always losing their bus tickets, but they never seem to lose their phones! It has been much, <strong>much</strong> simpler."</p>
                      <p className='case-study__quote-author'>&mdash; Rebecca King, Student Welfare Office, Plumpton College</p>
                    </div>

                    <p>Rebecca has also found the level of support from ShuttleID incredibly responsive. Rebecca said, “I remember emailing one weekend, and I got a response and a change on the website there and then.”</p>

                    <h2>Putting Plumpton in charge</h2>
                    <p>Since going live, Rebecca has used the journey logs to uncover travel patterns, which help her decide if further transport is needed on busy days, making sure nobody is left out in the cold.</p>
                    <p>In fact, she’s realised ShuttleID does a lot more than confirming she has the right passenger numbers; the system is paying dividends regarding safeguarding and behaviour issues. Recently, for example, when a student reported being bullied on the bus, but they didn't know who it was, Plumpton simply sifted through the passenger list and photos to identify the troublemaker. </p>

                    <h2>Clearer communication</h2>
                    <p>The SMS service text alerts are an added feature that helping with safeguarding. Rebecca recalls an emergency where she needed to get an important message out and required the details of those onboard a bus. Now she has that information at her fingertips and can send messages instantly. She said, “To have these features makes me feel much more in control.”</p>
                    <p>Overall, Plumpton College estimates that using ShuttleID has saved the cost of one extra full-time member of staff. Importantly, Rebecca now has much more time to focus on her role as Student Welfare Officer, rather than as the sorter of bus tickets!</p> */}


                    {/* <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Ceri Taylor" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"Recently, I was on holiday and, as usual, I took my laptop with me. Every other day I went on my ShuttleID portal to see how many tickets I’d sold and how close I was to my new targets. Compare that with two years ago? What a difference! With the customer numbers we’re doing now, the old system would have been Bedlam. This holiday I didn’t have to contact any parents or help set up standing orders. All I had to do was watch the numbers creep up. I’m now exceeding my targets, so it has definitely been a game changer."</p>
                        <p className='case-study__profile-quote__author'>Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
